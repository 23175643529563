@import url("https://fonts.googleapis.com/css?family=Orbitron");

.displayado {
  background-color: black;
  border: solid 10px "#E95725";
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px;
  margin-top: 10px;
}

.displayContainer {
  border-radius: 10px;
}

.red {
  display: flex;
  align-items: center;
  color: "#E95725";
  font-size: 18px;
  font-weight: bold;
  font-family: "Orbitron";
  margin-left: -30px;
  margin-right: 10px;
  margin-top: 24px;
}
